import {
  Avatar,
  AvatarGroup,
  Box,
  Paper,
  Typography,
  Button,
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import PlusOneIcon from '@mui/icons-material/PlusOne'

function Login() {
  return (
    <Box
      display="flex"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Box p={2} component={Paper}>
        <Box display="flex" justifyContent="center" mb={1}>
          <AvatarGroup>
            <Avatar sx={{ bgcolor: '#4CAF50' }}>
              <PersonIcon />
            </Avatar>

            <Avatar sx={{ color: '#DE2D1E' }}>V</Avatar>

            <Avatar sx={{ color: '#ED7D00' }}>V</Avatar>

            <Avatar sx={{ bgcolor: '#1F6CFA' }}>
              <PlusOneIcon />
            </Avatar>
          </AvatarGroup>
        </Box>

        <Typography variant="h6">Вход с помощью Яндекса</Typography>

        <Button sx={{mt: 1}} fullWidth variant="contained">
          Войти
        </Button>
      </Box>
    </Box>
  )
}

export default Login
