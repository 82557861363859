import { CssBaseline } from '@mui/material'

import Login from './pages/Login'

function App() {
  return (
    <>
      <CssBaseline />

      <Login />
    </>
  )
}

export default App
